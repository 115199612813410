import React from "react";
import { Route, Switch } from "react-router-dom";
import { browserHistory } from "react-router";

import { default as NoMatch } from "../../components/common/errors/404/page404";

import { loginRequired, logoutRequired } from "../../utils/routes";

import { privateRoutes } from "./private.routes";
import { publicRoutes } from "./public.routes";

let routes = [...privateRoutes, ...publicRoutes]


export default <Switch>
    {routes.map((route, i) =>
        <Route key={i} exact={route.subRoutes.some(r => r.exact)} path={route.subRoutes.map(r => r.path)} history={browserHistory}>
            <route.layout>
                {route.subRoutes.map((subRoute, i) => (
                    <Route key={i} exact={subRoute.exact} path={subRoute.path} component={
                        subRoute.authreq === 'login' ?
                            loginRequired(subRoute.component)
                            : subRoute.authreq === 'logout' ?
                                logoutRequired(subRoute.component)
                                : subRoute.component
                    } />
                ))}
            </route.layout>
        </Route>
    )}
    <Route path="*" component={NoMatch} />
</Switch>