import { lazy } from "react";

export const Home = lazy( () => import(/* webpackChunkName: "Home" */ '../../components/pages/Home/home'));
export const Login = lazy( () => import(/* webpackChunkName: "login" */ '../../components/pages/auth/login'));
export const TalentPlanSelfAssessment = lazy( () => import(/* webpackChunkName: "TalentPlanSelfAssessment" */ '../../components/pages/Talent/TalentPlanSelfAssessment'));
export const TalentProfileList = lazy( () => import(/* webpackChunkName: "talentProfileList" */ '../../components/pages/Talent/talentProfileList'));
export const TalentProfileMain = lazy( () => import(/* webpackChunkName: "talentProfileMain" */ '../../components/pages/Talent/Profile/talentProfileMain'));
export const TalentPlanEmployeeList = lazy( () => import(/* webpackChunkName: "talentPlanEmployeeList" */ '../../components/pages/Talent/talentPlanEmployeeList'));
export const CompetencyAssessment = lazy( () => import(/* webpackChunkName: "competencyAssessment" */ '../../components/pages/Competency/Assessment/competencyAssessment'));
export const CompetencySelfAssessmentList = lazy( () => import(/* webpackChunkName: "competencySelfAssessmentList" */ '../../components/pages/Competency/competencySelfAssessmentList'));
export const SuccessionPlanningList = lazy( () => import(/* webpackChunkName: "successionPlanningList" */ '../../components/pages/Succession/successionPlanningList'));
export const SuccessionPlanning = lazy( () => import(/* webpackChunkName: "successionPlanning" */ '../../components/pages/Succession/successionPlanning'));
export const SuccessionTermList = lazy( () => import(/* webpackChunkName: "successionTermList" */ '../../components/pages/Succession/successionTermList'));
export const SuccessionTermManagement = lazy( () => import(/* webpackChunkName: "successionTermManagement" */ '../../components/pages/Succession/successionTermManagement'));
export const TalentEmployeeAssessmentList = lazy( () => import(/* webpackChunkName: "talentEmployeeAssessmentList" */ '../../components/pages/Talent/talentEmployeeAssessmentList'));
export const PositionList = lazy( () => import(/* webpackChunkName: "positionList" */ '../../components/pages/Position/positionList'));
export const PositionManangement = lazy( () => import(/* webpackChunkName: "positionManagement" */ '../../components/pages/Position/positionManagement'));
export const PositionTypeManagementList = lazy( () => import(/* webpackChunkName: "positionTypeManagementList" */ '../../components/pages/Position/positionTypeManagementList'));
export const PositionTypeManagement = lazy( () => import(/* webpackChunkName: "positionTypeManagement" */ '../../components/pages/Position/positionTypeManagement'));
export const Dashboard = lazy( () => import(/* webpackChunkName: "reporting_dashboard" */ '../../components/pages/report/reporting_dashboard'));
export const SystemDefaultSettingsList = lazy( () => import(/* webpackChunkName: "systemDefaultSettingsList" */ '../../components/pages/System/Default/systemDefaultSettingsList'));
export const SystemDefaultSettings = lazy( () => import(/* webpackChunkName: "systemDefaultSettings" */ '../../components/pages/System/Default/systemDefaultSettings'));
export const TermsAndCondition = lazy( () => import(/* webpackChunkName: "termsAndConditions" */ '../../components/pages/termsAndConditions'));
export const ChangePassword = lazy( () => import(/* webpackChunkName: "changePassword" */ '../../components/pages/auth/changePassword'));
export const ActivePlanManagement = lazy( () => import(/* webpackChunkName: "activePlanManagement" */ '../../components/pages/Plan/activePlanManagement'));
export const CMS_FAQ = lazy( () => import(/* webpackChunkName: "faqForm" */ '../../components/pages/cms/faqForm'));
export const CompanyValueList = lazy( () => import(/* webpackChunkName: "companyValueList" */ '../../components/pages/company_value/companyValueList'));
export const CompanyValueManagement = lazy( () => import(/* webpackChunkName: "companyValueManagement" */ '../../components/pages/company_value/companyValueManagement'));
export const CompetencyTypeList = lazy( () => import(/* webpackChunkName: "competencyTypeList" */ '../../components/pages/Competency/Type/competencyTypeList'));
export const CompetencyType = lazy( () => import(/* webpackChunkName: "competencyTypeManagement" */ '../../components/pages/Competency/Type/competencyTypeManagement'));
export const CompetencyList = lazy( () => import(/* webpackChunkName: "competencyList" */ '../../components/pages/Competency/competencyList'));
export const Competency = lazy( () => import(/* webpackChunkName: "competencyManagement" */ '../../components/pages/Competency/competencyManagement'));
export const ConfirmationPage = lazy( () => import(/* webpackChunkName: "confirmationPage" */ '../../components/pages/auth/confirmationPage'));
export const DataManagementAccess = lazy( () => import(/* webpackChunkName: "dataManagementAccess" */ '../../components/pages/Data/Management/dataManagementAccess'));
export const DataManagementAccessList = lazy( () => import(/* webpackChunkName: "dataAccessManagementList" */ '../../components/pages/Data/Access/dataAccessManagementList'));
export const OrgChartPrimitivesV2 = lazy( () => import(/* webpackChunkName: "orgChartPrimitivesV2" */ '../../components/pages/Organizational/Chart/orgChartPrimitivesV2'));
export const SystemAccessLevelManagement = lazy( () => import(/* webpackChunkName: "systemAccessLevelManagement" */ '../../components/pages/System/AccessLevel/systemAccessLevelManagement'));
export const ApplicationFunctionAccessManagement = lazy( () => import(/* webpackChunkName: "applicationFunctionAccessManagement" */ '../../components/pages/ApplicationFunctionAccess/applicationFunctionAccessManagement'));
export const DocumentTypeList = lazy( () => import(/* webpackChunkName: "documentTypeList" */ '../../components/pages/document_type/documentTypeList'));
export const DocumentTypeManagement = lazy( () => import(/* webpackChunkName: "documentTypeManagement" */ '../../components/pages/document_type/documentTypeManagement'));
export const EmailScheduleList = lazy( () => import(/* webpackChunkName: "emailScheduleList" */ '../../components/pages/email/emailScheduleList'));
export const EmailSettings = lazy( () => import(/* webpackChunkName: "emailSettings" */ '../../components/pages/email/emailSettings'));
export const EmailTemplateList = lazy( () => import(/* webpackChunkName: "emailTemplateList" */ '../../components/pages/email/template/emailTemplateList'));
export const EmailTemplateManagement = lazy( () => import(/* webpackChunkName: "emailTemplateManagement" */ '../../components/pages/email/template/emailTemplateManagement'));
export const EmailTypeList = lazy( () => import(/* webpackChunkName: "emailTypeList" */ '../../components/pages/email/type/emailTypeList'));
export const EmailTypeManagement = lazy( () => import(/* webpackChunkName: "emailTypeManagement" */ '../../components/pages/email/type/emailTypeManagement'));
export const EmployeeClassificationList = lazy( () => import(/* webpackChunkName: "employeeClassificationList" */ '../../components/pages/Employee/Classification/employeeClassificationList'));
export const EmployeeClassificationManagement = lazy( () => import(/* webpackChunkName: "EmployeeClassificationManagement" */ '../../components/pages/Employee/Classification/EmployeeClassificationManagement'));
export const EmployeeDataList = lazy( () => import(/* webpackChunkName: "employeeManagementList" */ '../../components/pages/Employee/Management/employeeManagementList'));
export const EmployeeManagement = lazy( () => import(/* webpackChunkName: "employeeManagement" */ '../../components/pages/Employee/Management/employeeManagement'));
export const EmployeeDisciplineManagement = lazy( () => import(/* webpackChunkName: "employeeDisciplineManagement" */ '../../components/pages/Employee/employeeDisciplineManagement'));
export const FAQ = lazy( () => import(/* webpackChunkName: "faq" */ '../../components/pages/faq'));
export const ForgotPassword = lazy( () => import(/* webpackChunkName: "forgotPassword" */ '../../components/pages/auth/forgotPassword'));
export const FunctionalManagerManagement = lazy( () => import(/* webpackChunkName: "functionalManagerManagement" */ '../../components/pages/FuntionalManager/functionalManagerManagement'));
export const HomeCMS = lazy( () => import(/* webpackChunkName: "homeTemplate" */ '../../components/pages/cms/homeTemplate'));
export const IPControlList = lazy( () => import(/* webpackChunkName: "ipControlList" */ '../../components/pages/IpControl/ipControlList'));
export const IPControlManagement = lazy( () => import(/* webpackChunkName: "ipControlManagement" */ '../../components/pages/IpControl/ipControlManagement'));
export const LanguageTable = lazy( () => import(/* webpackChunkName: "Language" */ '../../components/pages/cms/Language'));
export const LanguageTableList = lazy( () => import(/* webpackChunkName: "LanguageTable" */ '../../components/pages/languageManagement/LanguageTable'));
export const LocationLogonManagement = lazy( () => import(/* webpackChunkName: "locationLogonManagement" */ '../../components/pages/Location/locationLogonManagement'));
export const LocationManagement = lazy( () => import(/* webpackChunkName: "locationManagement" */ '../../components/pages/Location/locationManagement'));
export const LocationTypeList = lazy( () => import(/* webpackChunkName: "locationTypeList" */ '../../components/pages/Location/Type/locationTypeList'));
export const LocationTypeManagement = lazy( () => import(/* webpackChunkName: "locationTypeManagement" */ '../../components/pages/Location/Type/locationTypeManagement'));
export const CurrencyManagement = lazy( () => import(/* webpackChunkName: "currencyManagement" */ '../../components/pages/Currency/Management/currencyManagement'));
export const MyTeam = lazy( () => import(/* webpackChunkName: "myTeam" */ '../../components/pages/MyTeam/myTeam'));
export const ObjectiveTypeList = lazy( () => import(/* webpackChunkName: "objectiveTypeList" */ '../../components/pages/Objective/Type/objectiveTypeList'));
export const ObjectiveTypeManagement = lazy( () => import(/* webpackChunkName: "objectiveTypeManagement" */ '../../components/pages/Objective/Type/objectiveTypeManagement'));
export const ObjectiveWeightList = lazy( () => import(/* webpackChunkName: "objectiveWeightList" */ '../../components/pages/Objective/Weight/objectiveWeightList'));
export const ObjectiveWeightManagement = lazy( () => import(/* webpackChunkName: "objectiveWeightManagement" */ '../../components/pages/Objective/Weight/objectiveWeightManagement'));
export const OrganizationalUnitList = lazy( () => import(/* webpackChunkName: "organizationalUnitList" */ '../../components/pages/Organizational/organizationalUnitList'));
export const Page404 = lazy( () => import(/* webpackChunkName: "page404" */ '../../components/common/errors/404/page404'));
export const PerformanceRatingList = lazy( () => import(/* webpackChunkName: "performanceRatingList" */ '../../components/pages/Performance/Rating/performanceRatingList'));
export const PerformanceRatingManagement = lazy( () => import(/* webpackChunkName: "performanceRatingManagement" */ '../../components/pages/Performance/Rating/performanceRatingManagement'));
export const PlanEmployeePhaseManagement = lazy( () => import(/* webpackChunkName: "planEmployeePhaseManagement" */ '../../components/pages/Plan/Employee/planEmployeePhaseManagement'));
export const PlanEmployeePerformanceManagement = lazy( () => import(/* webpackChunkName: "planEmployeePerformanceManagement" */ '../../components/pages/Plan/Employee/planEmployeePerformanceManagement'));
export const PlanList = lazy( () => import(/* webpackChunkName: "planList" */ '../../components/pages/Plan/List/planList'));
export const PlanListFunctionalManager = lazy( () => import(/* webpackChunkName: "planListFunctionalManager" */ '../../components/pages/Plan/List/planListFunctionalManager'));
export const PlanListPersonal = lazy( () => import(/* webpackChunkName: "planListPersonal" */ '../../components/pages/Plan/List/planListPersonal'));
export const PlanManagement = lazy( () => import(/* webpackChunkName: "planManagement" */ '../../components/pages/Plan/planManagement'));
export const PlanObjectives = lazy( () => import(/* webpackChunkName: "planObjectives" */ '../../components/pages/Plan/planObjectives'));
export const PositionHierarchyManagement = lazy( () => import(/* webpackChunkName: "positionHierarchyManagement" */ '../../components/pages/Position/positionHierarchyManagement'));
export const SendEmail = lazy( () => import(/* webpackChunkName: "sendEmail" */ '../../components/pages/email/sendEmail'));
export const SetupPassword = lazy( () => import(/* webpackChunkName: "setupPassword" */ '../../components/pages/auth/setupPassword'));
export const SysMDDBObjectAttributeList = lazy( () => import(/* webpackChunkName: "sysMDDBObjectAttributeList" */ '../../components/pages/System/sysMDDBObjectAttributeList'));
export const SysMDDBObjectList = lazy( () => import(/* webpackChunkName: "sysMDDBObjectList" */ '../../components/pages/System/sysMDDBObjectList'));
export const SysMDAttributeTypeList = lazy( () => import(/* webpackChunkName: "sysMDAttributeTypeList" */ '../../components/pages/System/sysMDAttributeTypeList'));
export const SysMDAttributeTypeManagement = lazy( () => import(/* webpackChunkName: "sysMDAttributeTypeManagement" */ '../../components/pages/System/sysMDAttributeTypeManagement'));
export const TalentManagementPlan = lazy( () => import(/* webpackChunkName: "talentManagementPlan" */ '../../components/pages/Talent/Management/talentManagementPlan'));
export const TalentManagementPlanList = lazy( () => import(/* webpackChunkName: "talentManagementPlanList" */ '../../components/pages/Talent/Management/talentManagementPlanList'));
export const TermsAndConditionTemplates = lazy( () => import(/* webpackChunkName: "TermsAndConditionTemplate" */ '../../components/pages/cms/TermsAndConditionTemplate'));
export const Upload = lazy( () => import(/* webpackChunkName: "Upload" */ '../../components/pages/cms/Upload'));
export const UserApplicationSettingsList = lazy( () => import(/* webpackChunkName: "userApplicationSettingsList" */ '../../components/pages/userApplicationSettingsList'));
export const LegalEntitiesManagement = lazy( () => import(/* webpackChunkName: "legalEntitiesManagement" */ '../../components/pages/legal_/legalEntitiesManagement'));
export const LegalEntitiesManagementList = lazy( () => import(/* webpackChunkName: "legalEntitiesManagementList" */ '../../components/pages/legal_/legalEntitiesManagementList'));
export const TagCategoryManagement = lazy( () => import(/* webpackChunkName: "tagCategory" */ '../../components/pages/tag_/tagCategory'));
export const PositionClassManagement = lazy( () => import(/* webpackChunkName: "positionClassManagement" */ '../../components/pages/Position/positionClassManagement'));
export const PositionClassManagementList = lazy( () => import(/* webpackChunkName: "positionClassManagementList" */ '../../components/pages/Position/positionClassManagementList'));
export const BonusPlanList = lazy( () => import(/* webpackChunkName: "bonusPlanList" */ '../../components/pages/Bonus/Plan/bonusPlanList'));
export const BonusPlan = lazy( () => import(/* webpackChunkName: "bonusPlan" */ '../../components/pages/Bonus/Plan/bonusPlan'));
export const BonusObjectives = lazy( () => import(/* webpackChunkName: "bonusObjectivesTree" */ '../../components/pages/Bonus/Objectives/bonus_objectives_tree'));
export const MyTeamBonus = lazy( () => import(/* webpackChunkName: "employeeBonusList" */ '../../components/pages/Employee/Bonus/employeeBonusList'));
export const BonusProcessRulesList = lazy( () => import(/* webpackChunkName: "bonusProcessRulesList" */ '../../components/pages/Bonus/ProcessRules/bonusProcessRulesList'));
export const BonusProcessRulesManagement = lazy( () => import(/* webpackChunkName: "bonusProcessRulesManagement" */ '../../components/pages/Bonus/ProcessRules/bonusProcessRulesManagement'));
export const BonusPerformanceMultiplierList = lazy( () => import(/* webpackChunkName: "bonusPerformanceMultiplierList" */ '../../components/pages/Bonus/bonusPerformanceMultiplierList'));
export const EmployeeBonusOverviewList = lazy( () => import(/* webpackChunkName: "employeeBonusOverviewList" */ '../../components/pages/Employee/Bonus/employeeBonusOverviewList'));
export const EmployeeBonusOverviewManagement2 = lazy( () => import(/* webpackChunkName: "generalEmployeeBonusManagement" */ '../../components/pages/Employee/Bonus/Management/generalEmployeeBonusManagement'));
export const CurrencyView = lazy( () => import(/* webpackChunkName: "currencyView" */ '../../components/pages/Currency/currency_view'));
export const CurrencyFXManagement = lazy( () => import(/* webpackChunkName: "currencyFxManagement" */ '../../components/pages/Currency/currency_fx_management'));
export const ReportingDashboard = lazy( () => import(/* webpackChunkName: "reportingDashboard" */ '../../components/pages/report/reporting_dashboard'));
export const SSO = lazy( () => import(/* webpackChunkName: "ssoList" */ '../../components/pages/sso-testing/ssoList'));
export const StaticLabels = lazy( () => import(/* webpackChunkName: "staticLabels" */ '../../components/pages/cms/staticLabels'));
export const LanguageManagement = lazy( () => import(/* webpackChunkName: "languageManagementData" */ '../../components/pages/languageManagement/languageManagementData'));
export const StaticLanguageManagement = lazy( () => import(/* webpackChunkName: "staticLanguageManagement" */ '../../components/pages/languageManagement/staticLanguageManagement'));
export const DefaultLabelManagement = lazy( () => import(/* webpackChunkName: "defaultLabelManagement" */ '../../components/pages/languageManagement/defaultLabelManagement'));
export const AdalFrame = lazy( () => import(/* webpackChunkName: "adalFrame" */ '../../components/adalLogin/adalFrame'));
export const EmploymentHistoryReasonManagement = lazy( () => import(/* webpackChunkName: "employeeHistoryReasonManagement" */ '../../components/pages/Employee/History/employeeHistoryReasonManagement'));
export const EmploymentHistoryReasonList = lazy( () => import(/* webpackChunkName: "employeeHistoryReasonList" */ '../../components/pages/Employee/History/employeeHistoryReasonList'));
export const Logout = lazy( () => import(/* webpackChunkName: "logout" */ '../../components/pages/auth/logout'));
export const BonusApprovalWorkflowView = lazy( () => import(/* webpackChunkName: "bonusWorkflowView" */ '../../components/pages/Bonus/Workflow/bonus_workflow_view'));
export const BonusWorkFlowList = lazy( () => import(/* webpackChunkName: "bonus_workflow_list" */ '../../components/pages/Bonus/Workflow/bonus_workflow_list'));
export const BonusWorkFlowManagement = lazy( () => import(/* webpackChunkName: "bonus_workflow_list_management" */ '../../components/pages/Bonus/Workflow/bonus_workflow_list_management'));
export const BonusWorkFlowStateRoleList = lazy( () => import(/* webpackChunkName: "bonus_workflow_state_role_list" */ '../../components/pages/Bonus/Workflow/bonus_workflow_state_role_list'));
export const BonusWorkFlowStateRoleManagement = lazy( () => import(/* webpackChunkName: "bonus_workflow_state_role_list_management" */ '../../components/pages/Bonus/Workflow/bonus_workflow_state_role_list_management'));
export const BonusWorkFlowStateTransitionList = lazy( () => import(/* webpackChunkName: "bonus_workflow_state_transition_list" */ '../../components/pages/Bonus/Workflow/bonus_workflow_state_transition_list'));
export const BonusWorkFlowStateTransitionManagement = lazy( () => import(/* webpackChunkName: "bonus_workflow_state_transition_list_mangement" */ '../../components/pages/Bonus/Workflow/bonus_workflow_state_transition_list_mangement'));
export const BonusWorkflowStateLists = lazy( () => import(/* webpackChunkName: "bonus_workflow_statelist" */ '../../components/pages/Bonus/Workflow/bonus_workflow_statelist'));
export const BonusWorkflowStateListManagement = lazy( () => import(/* webpackChunkName: "bonus_workflow_statelist_management" */ '../../components/pages/Bonus/Workflow/bonus_workflow_statelist_management'));
export const OrganisationUnitTypeList = lazy( () => import(/* webpackChunkName: "organisation_unit_list" */ '../../components/pages/Organizational/organisation_unit_list'));
export const OrganisationUnitTypeManagement = lazy( () => import(/* webpackChunkName: "organisation_unit_type_management" */ '../../components/pages/Organizational/organisation_unit_type_management'));
export const PositionDetailsTabs = lazy( () => import(/* webpackChunkName: "jobDescription_view" */ '../../components/pages/Position/jobDescription/jobDescription_view'));
export const LineBusinessList = lazy( () => import(/* webpackChunkName: "lineBusinessList" */ '../../components/pages/line_of_business/lineBusinessList'));
export const LineBusinessListManagement = lazy( () => import(/* webpackChunkName: "lineBusinessManagement" */ '../../components/pages/line_of_business/lineBusinessManagement'));
export const PerformanceTypeList = lazy( () => import(/* webpackChunkName: "performceTypeList" */ '../../components/pages/Performance/Type/performceTypeList'));
export const PerformanceTypeManagement = lazy( () => import(/* webpackChunkName: "performanceTypeManagement" */ '../../components/pages/Performance/Type/performanceTypeManagement'));
export const PerformanceTypeExplanationList = lazy( () => import(/* webpackChunkName: "performanceTypeExplanationList" */ '../../components/pages/Performance/Type/performanceTypeExplanationList'));
export const PerformanceTypeExplanationManagement = lazy( () => import(/* webpackChunkName: "performanceTypeExplanationManagement" */ '../../components/pages/Performance/Type/performanceTypeExplanationManagement'));
//KPI
export const KPIList = lazy( () =>  import(/* webpackChunkName: "KPI_List" */ '../../components/pages/KPI/KPI_List'));
export const KPIManagement = lazy( () =>  import(/* webpackChunkName: "KPI_Management" */ '../../components/pages/KPI/KPI_Management'));