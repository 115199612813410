import React from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import Sidebar from './sidebar';
import Navbar from './navbar';
import ChildNavbar from './child-navbar';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'bulma/css/bulma.css';
import '../../../assets/static/bulkit/assets/css/icons.min.css';
import '../../../assets/static/bulkit/scss/dashboard.scss';
import '../../../assets/css/style.scss';
import "../../../assets/static/demo/js/jquery/ui-lightness/jquery-ui-1.10.2.custom.css";

import { toggleLabelAction } from '../../../actions/toggleLabelAction';
import { bulkitFunctions } from './helpers/functions';
import GeneralFunctions from "../../../functions/general_function1";


var reportListDataSource = axios.CancelToken.source();


class BulmaPrivateLayout extends React.Component {   
  constructor(props) {
    super(props);
    this.state = {
      numberOfDrawerNotification: 0,
      numberOfNotification: 1,
      reportingNotification: [],
      labelToggle: false,
    }
    
    this.getTranslatableChangeState = this.getTranslatableChangeState.bind(this);
    this.fetchGeneratedReports = this.fetchGeneratedReports.bind(this); 
  }

  componentDidMount() {
    bulkitFunctions();
    this.fetchGeneratedReports(moment().format("DD/MM/YYYY").toString());
  }

  fetchGeneratedReports(dateDownloaded) {
    reportListDataSource.cancel(
      "Cancel request because another request will be made."
    );
  
    let self = this,
      newCancelToken = axios.CancelToken,
      newSource = newCancelToken.source(),
      params = {
        DateDownloaded: dateDownloaded,
      },
      requestjson = {
        Module: "system_report_notifications",
        Parameters: params,
      },
      config = {
        CancelToken: reportListDataSource,
        Data: { requestjson: requestjson },
        Method: "GET",
        ResponseSuccessCallback: responseSuccessCallback,
        ResponseFailCallback: responseFailCallback,
        Url: "/single_api/",
      };

    config = GeneralFunctions.genericQuery(config);
    reportListDataSource = config.CancelToken;

    function responseSuccessCallback(responseJson) {
      let data = responseJson.data;

      if (data.Status.IsSuccess) {
        let Data = data.Data;
        self.setState({
          reportingNotification: Data.DownloadedReports,
        });
      }
    }

    function responseFailCallback(responseJson) {
      console.log("responseFailCallback", responseJson);
    }
  }
  
  updateParentState = (stateValue) => {
    this.setState(stateValue);
  }
  
  getTranslatableChangeState() {
    
    this.props.toggleLabel('')
    this.setState({ 
      labelToggle: !this.state.labelToggle 
    });
      
  }

  render() {
    return (
        <div>
            <ToastContainer limit={1}/>
            <Sidebar />
            <Navbar 
            updateParentState={this.updateParentState}
            labelToggle={this.state.labelToggle}
            getTranslatableChangeState={this.getTranslatableChangeState}
            />
            <ChildNavbar 
              numberOfNotification={this.state.numberOfNotification}
              numberOfDrawerNotification={this.state.numberOfDrawerNotification}
            />
            <div id="dashboard-wrapper">
              {this.props.children}
            </div>
        </div>
    )
  }

};

const mapStateToProps = state => ({
  toggleLabelReducer: state.toggleLabelReducer
})

const mapDispatchToProps = dispatch => ({
  toggleLabel: (data) => dispatch(toggleLabelAction(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(BulmaPrivateLayout);