import Layout2 from "../../components/layouts/layout-2";

import * as Component from "./components";


export const publicRoutes = [{
    layout: Layout2,
    subRoutes: [{
        authreq: "logout", exact: true, path: "/login", component: Component.Login
    }, {
        authreq: "logout", exact: true, path: "/setup-password", component: Component.SetupPassword
    }, {
        authreq: "logout", exact: true, path: "/forgot-password", component: Component.ForgotPassword
    }, {
        authreq: "free", exact: true, path: "/faqs", component: Component.FAQ
    }, {
        authreq: "free", exact: true, path: "/adal-frame", component: Component.AdalFrame
    }]
}]
