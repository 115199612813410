var ReactDOM = require('react-dom');
import { BrowserRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux'
import configureStore from './store/index';

import { approutes } from "./config/routes";

import * as serviceWorker from "./serviceWorker";
import Loading from './components/pages/Loading';


ReactDOM.render((
        <Provider store={configureStore()}>
                <BrowserRouter>
                        <Suspense fallback={<Loading/>}>
                                {approutes}
                        </Suspense>
                </BrowserRouter>
        </Provider>
), document.getElementById('main-container'));
serviceWorker.unregister();