let $ = window.$,
    jQuery = window.jQuery;


export const bulkitFunctions = () => {

    "use strict";

    //Page loader
    if ($('.pageloader').length) {

        $('.pageloader').toggleClass('is-active');

        $(window).on('load', function () {
            var pageloaderTimeout = setTimeout(function () {
                $('.pageloader').toggleClass('is-active');
                $('.infraloader').toggleClass('is-active')
                clearTimeout(pageloaderTimeout);
            }, 700);
        })
    }

    //Mobile menu toggle
    if ($('.nav-toggle').length) {
        $('.nav-toggle').on("click", function () {
            $(this).toggleClass('is-active');
            $(this).siblings('.nav-menu').toggleClass('is-active');
        })
    }

    //Main menu icon behaviour and push sidebar
    $('.side-icon').on("click", function () {
        if ($(this).find('a').attr('href')) {
            $('.side-icon.is-active').removeClass('is-active');
            $('.reader-switch label').removeClass('is-disabled');
            $('.menu-wrapper .icon-box-toggle').addClass('active');
            $('.child-menu').removeClass('is-sidebar-translated');
            $('.dashboard-nav, #dashboard-wrapper').removeClass('is-pushed');
        } else {
            if ($(this).attr('class') === "side-icon is-active") {
                $('.side-icon.is-active').removeClass('is-active');
                $('.menu-wrapper .icon-box-toggle').addClass('active');
                $('.child-menu').removeClass('is-sidebar-translated');
                $('.dashboard-nav, #dashboard-wrapper').removeClass('is-pushed');
            } else {
                $('.side-icon.is-active').removeClass('is-active');
                $(this).addClass('is-active');
                $('.menu-wrapper .icon-box-toggle').addClass('active');
                $('.child-menu').addClass('is-sidebar-translated');
                $('.dashboard-nav, #dashboard-wrapper').addClass('is-pushed');
                //disable reader mode switch when sidebar is opened
                $('.reader-switch label').addClass('is-disabled');
            }
        }
    });

    $('.sidebar-menu li').on('click', function () {
        if ($(this).find('> a').attr('href') !== undefined) {
            $('.side-icon.is-active').removeClass('is-active');
            $('.menu-wrapper .icon-box-toggle').addClass('active');
            $('.child-menu').removeClass('is-sidebar-translated');
            $('.dashboard-nav, #dashboard-wrapper').removeClass('is-pushed');
        }
    });

    $('.menu-wrapper').on("click", function () {
        $('.child-menu').toggleClass('is-sidebar-translated');
        $('.dashboard-nav, #dashboard-wrapper').toggleClass('is-pushed');
        //enable reader mode switch when sidebar is closed
        $('.reader-switch label').removeClass('is-disabled');
    })

    //Sidebar menu submenu transitions
    $(".sidebar-menu > li.have-children a.parent-link").on("click", function (i) {
        i.preventDefault();
        if (!$(this).parent().hasClass("active")) {
            $(".sidebar-menu li ul").slideUp();
            $(this).next().slideToggle();
            $(".sidebar-menu li").removeClass("active");
            $(this).parent().addClass("active");
        }
        else {
            $(this).next().slideToggle();
            $(".sidebar-menu li").removeClass("active");
        }
    });

    //Data child menu setup
    $('.main-menu ul li.side-icon').on("click", function () {
        var menu_id = $(this).attr('data-child-menu');
        $('.sidebar-menu.is-active').removeClass('is-active');
        $('.reader-switch label').removeClass('is-disabled');
        $("#" + menu_id).addClass('is-active');
    })

    //Reader mode (only for mobile)
    $('#reader-mode-toggle').on("click", function () {
        $('body').toggleClass('reader-mode');
        $('.mobile-nav').toggleClass('is-fullwidth');
    })

    //Handle mobile nav
    $('#mobile-dashboard-trigger').on('click', function () {
        $('.mobile-nav').toggleClass('is-active');
        $('.dashboard-wrapper').toggleClass('is-pushed-nav-mobile');
        $('.icon-box-toggle').toggleClass('active');
    })

    //Common dropdown
    $(function () {
        $('.dropdown-trigger').click(function () {
            $('.dropdown').removeClass('is-active');
            $(this).addClass('is-active');
        });

        $(document).click(function (e) {
            var target = e.target;
            if (!$(target).is('.dropdown img') && !$(target).parents().is('.dropdown')) {
                $('.dropdown').removeClass('is-active');
            }
        });

    });

    //Navigation Tabs
    $('.navigation-tabs ul li').on("click", function () {
        var tab_id = $(this).attr('data-tab');

        $(this).siblings('li').removeClass('is-active');
        $(this).closest('.navigation-tabs').children('.navtab-content').removeClass('is-active');

        $(this).addClass('is-active');
        $("#" + tab_id).addClass('is-active');
    })

    //Ripple effect
    if ($('.ripple').length) {
        (function (window, $) {

            $(function () {

                $('.ripple').on('click', function (event) {
                    event.preventDefault();

                    var $div = $('<div/>'),
                        btnOffset = $(this).offset(),
                        xPos = event.pageX - btnOffset.left,
                        yPos = event.pageY - btnOffset.top;



                    $div.addClass('ripple-effect');
                    var $ripple = $(".ripple-effect");

                    $ripple.css("height", $(this).height());
                    $ripple.css("width", $(this).height());
                    $div
                        .css({
                            top: yPos - ($ripple.height() / 2),
                            left: xPos - ($ripple.width() / 2),
                            background: $(this).data("ripple-color")
                        })
                        .appendTo($(this));

                    window.setTimeout(function () {
                        $div.remove();
                    }, 2000);
                });

            });

        })(window, jQuery);
    }

    //Custom quickview initialization with data attributes
    if ($('.quickview').length) {
        var quickID;
        $('.quickview-trigger').on("click", function () {
            quickID = $(this).attr('data-target');
            $('#' + quickID).addClass('is-active');
        })
        $('.quickview-close').on("click", function () {
            quickID = $(this).attr('data-dismiss');
            $('#' + quickID).removeClass('is-active');
        })
    }

    //Accordion initialization
    var $accor = $('.accordion');
    $accor.each(function () {
        $(this).toggleClass('ui-accordion ui-widget ui-helper-reset');
        $(this).find('h3').addClass('ui-accordion-header ui-helper-reset ui-state-default ui-accordion-icons ui-corner-all');
        $(this).find('div').addClass('ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom');
        $(this).find("div").hide();
    });
    var $trigger = $accor.find('h3');
    $trigger.on('click', function (e) {
        var location = $(this).parent();
        if ($(this).next().is(':hidden')) {
            var $triggerloc = $('h3', location);
            $triggerloc.removeClass('ui-accordion-header-active ui-state-active ui-corner-top').next().slideUp(300);
            $triggerloc.find('span').removeClass('ui-accordion-icon-active');
            $(this).find('span').addClass('ui-accordion-icon-active');
            $(this).addClass('ui-accordion-header-active ui-state-active ui-corner-top').next().slideDown(300);
        }
        e.preventDefault();
    });
    $(".toggle-container").hide();
    $('.trigger, .trigger.opened').on('click', function (a) {
        $(this).toggleClass('active');
        a.preventDefault();
    });
    $(".trigger").on('click', function () {
        $(this).next(".toggle-container").slideToggle(300);
    });
    $(".trigger.opened").addClass("active").next(".toggle-container").show();

    //Adding the styled checkbox styles
    $(":checkbox").addClass('styled-checkbox');

    //Pop Dropdowns
    $('.drop-pop > .nav-inner').on('click', function (event) {
        event.stopPropagation();
        $(this).parent().children('.drop-wrapper').toggle();
    })
    //Close pop dropdowns on click outside
    $(window).on('click', function (event) {
        if ($('.drop-wrapper').length) {
            if ($('.drop-wrapper').is(":visible")) {
                $('.drop-wrapper').hide();
            }
        }
    });

    $('#close-child-menu').on('click', function () {
        $('.side-icon.is-active').removeClass('is-active');
        $('.reader-switch label').removeClass('is-disabled');
    });

    //BUOT BUOT NI MIGZ
    $(document).keyup(function (e) {
        if (e.key === "Escape") {
            $('.side-icon.is-active').removeClass('is-active');
            $('.reader-switch label').removeClass('is-disabled');
            $('.menu-wrapper .icon-box-toggle').addClass('active');
            $('.child-menu').removeClass('is-sidebar-translated');
            $('.dashboard-nav, #dashboard-wrapper').removeClass('is-pushed');
        }
    });
    
    // TO REMOVE THE TOGGLE WHEN LOGGING OUT
    // (P.S. WITHOUT THIS THE LOGOUT TOGGLE REMAINS 
    // AND WILL INCREMENT EVERY LOGOUT)
    $(document).click(function (e) {
        $('#main-container + div').remove();
        $(".ui-tooltip-content").parents('div').remove();
    });
    
}