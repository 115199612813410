import BulmaPrivateLayout from "../../components/layouts/bulma-private";

import * as Component from "./components";

export const privateRoutes = [{
    layout: BulmaPrivateLayout,
    subRoutes: [
    {
        authreq: "login", exact: true, path: "/", component: Component.Home
    }, {
        authreq: "login", exact: true, path: "/self-talent-assessment-list", component: Component.TalentPlanSelfAssessment
    }, {
        authreq: "login", exact: true, path: "/talent-assessment-list", component: Component.TalentProfileList
    }, {
        authreq: "login", exact: true, path: "/talent-assessment-list/:talentPlanID&:employeeID", component: Component.TalentProfileMain
    }, {
        authreq: "login", exact: true, path: "/competency-assessment", component: Component.TalentPlanEmployeeList
    }, {
        authreq: "login", exact: true, path: "/competency-assessment/:TMEmpPosAssessmentID", component: Component.CompetencyAssessment
    }, {
        authreq: "login", exact: true, path: "/self-competency-assessment", component: Component.CompetencySelfAssessmentList
    }, {
        authreq: "login", exact: true, path: "/succession-planning", component: Component.SuccessionPlanningList
    }, {
        authreq: "login", exact: true, path: "/succession-planning/:id", component: Component.SuccessionPlanning
    }, {
        authreq: "login", exact: true, path: "/succession-term-list", component: Component.SuccessionTermList
    }, {
        authreq: "login", exact: true, path: "/succession-term-list/succession-term", component: Component.SuccessionTermManagement
    }, {
        authreq: "login", exact: true, path: "/manage-employee-talent-assessment", component: Component.TalentEmployeeAssessmentList
    }, {
        authreq: "login", exact: true, path: "/position-list", component: Component.PositionList
    }, {
        authreq: "login", exact: true, path: "/position-list/position-management", component: Component.PositionManangement
    }, {
        authreq: "login", exact: true, path: "/position-list/:id", component: Component.PositionDetailsTabs
    }, {
        authreq: "login", exact: true, path: "/position-type-management-list", component: Component.PositionTypeManagementList
    }, {
        authreq: "login", exact: true, path: "/position-type-management-list/position-type-management", component: Component.PositionTypeManagement
    }, {
        authreq: "login", exact: true, path: "/position-type-management-list/:id", component: Component.PositionTypeManagement
    }, {
        authreq: "login", exact: true, path: "/report-dashboard", component: Component.Dashboard
    }, {
        authreq: "login", exact: true, path: "/system-default-settings-list", component: Component.SystemDefaultSettingsList
    }, {
        authreq: "login", exact: true, path: "/system-default-settings-list/system-default-settings", component: Component.SystemDefaultSettings
    }, {
        authreq: "login", exact: true, path: "/terms-and-condition", component: Component.TermsAndCondition
    }, {
        authreq: "login", exact: true, path: "/system-default-settings-list/:id", component: Component.SystemDefaultSettings
    }, {
        authreq: "login", exact: true, path: "/change-password", component: Component.ChangePassword
    }, {
        authreq: "login", exact: true, path: "/my-team", component: Component.MyTeam
    }, {
        authreq: "login", exact: true, path: "/employee-list", component: Component.EmployeeDataList
    }, {
        authreq: "login", exact: true, path: "/employee-list/employee-management", component: Component.EmployeeManagement
    }, {
        authreq: "login", exact: true, path: "/employee-list/:id", component: Component.EmployeeManagement
    }, {
        authreq: "login", exact: true, path: "/organisation-chart", component: Component.OrgChartPrimitivesV2
    }, {
        authreq: "login", exact: true, path: "/confirmation-page", component: Component.ConfirmationPage
    }, {
        authreq: "login", exact: true, path: "/organizational-unit-list", component: Component.OrganizationalUnitList
    }, {
        authreq: "login", exact: true, path: "/employee-discipline-list", component: Component.EmployeeDisciplineManagement
    }, {
        authreq: "login", exact: true, path: "/location-list", component: Component.LocationManagement
    }, {
        authreq: "login", exact: true, path: "/location-logon-management", component: Component.LocationLogonManagement
    }, {
        authreq: "login", exact: true, path: "/location-type-list", component: Component.LocationTypeList
    }, {
        authreq: "login", exact: true, path: "/location-type-list/:id", component: Component.LocationTypeManagement
    }, {
        authreq: "login", exact: true, path: "/document-type-list", component: Component.DocumentTypeList
    }, {
        authreq: "login", exact: true, path: "/document-type-list/document-type-management", component: Component.DocumentTypeManagement
    }, {
        authreq: "login", exact: true, path: "/document-type-list/:id", component: Component.DocumentTypeManagement
    }, {
        authreq: "login", exact: true, path: "/data-management-access-list", component: Component.DataManagementAccessList
    }, {
        authreq: "login", exact: true, path: "/data-management-access-list/:id", component: Component.DataManagementAccess
    }, {
        authreq: "login", exact: true, path: "/language-management", component: Component.LanguageManagement
    }, {
        authreq: "login", exact: true, path: "/permission-management", component: Component.SystemAccessLevelManagement
    }, {
        authreq: "login", exact: true, path: "/application-function-management", component: Component.ApplicationFunctionAccessManagement
    }, {
        authreq: "login", exact: true, path: "/position-hierarchy-list", component: Component.PositionHierarchyManagement
    }, {
        authreq: "login", exact: true, path: "/functional-manager-list", component: Component.FunctionalManagerManagement
    }, {
        authreq: "login", exact: true, path: "/currency-management-list", component: Component.CurrencyView
    }, {
        authreq: "login", exact: true, path: "/currency-management-list/currency-management", component: Component.CurrencyManagement
    }, {
        authreq: "login", exact: true, path: "/currency-management-list/currency/:id", component: Component.CurrencyManagement
    }, {
        authreq: "login", exact: true, path: "/hierarchy-discipline", component: Component.PositionHierarchyManagement
    }, {
        authreq: "login", exact: true, path: "/send-reminder", component: Component.SendEmail
    }, {
        authreq: "login", exact: true, path: "/email-schedule-list", component: Component.EmailScheduleList
    }, {
        authreq: "login", exact: true, path: "/email-type-list", component: Component.EmailTypeList
    }, {
        authreq: "login", exact: true, path: "/email-type-list/email-type-management", component: Component.EmailTypeManagement
    }, {
        authreq: "login", exact: true, path: "/email-type-list/:id", component: Component.EmailTypeManagement
    }, {
        authreq: "login", exact: true, path: "/email-template-list", component: Component.EmailTemplateList
    }, {
        authreq: "login", exact: true, path: "/email-template-list/email-template-management", component: Component.EmailTemplateManagement
    }, {
        authreq: "login", exact: true, path: "/email-template-list/:id", component: Component.EmailTemplateManagement
    }, {
        authreq: "login", exact: true, path: "/email-settings", component: Component.EmailSettings
    }, {
        authreq: "login", exact: true, path: "/competency-type-list", component: Component.CompetencyTypeList
    }, {
        authreq: "login", exact: true, path: "/competency-type-list/competency-type", component: Component.CompetencyType
    }, {
        authreq: "login", exact: true, path: "/competency-type-list/:id", component: Component.CompetencyType
    }, {
        authreq: "login", exact: true, path: "/competency-list", component: Component.CompetencyList
    }, {
        authreq: "login", exact: true, path: "/competency-list/competency", component: Component.Competency
    }, {
        authreq: "login", exact: true, path: "/competency-list/:id", component: Component.Competency
    }, {
        authreq: "login", exact: true, path: "/plan-management", component: Component.PlanManagement
    }, {
        authreq: "login", exact: true, path: "/active-plan-management", component: Component.ActivePlanManagement
    }, {
        authreq: "login", exact: true, path: "/objective-weight-list", component: Component.ObjectiveWeightList
    }, {
        authreq: "login", exact: true, path: "/objective-weight-list/objective-weight", component: Component.ObjectiveWeightManagement
    }, {
        authreq: "login", exact: true, path: "/objective-weight-list/:id", component: Component.ObjectiveWeightManagement
    }, {
        authreq: "login", exact: true, path: "/company-value-list", component: Component.CompanyValueList
    }, {
        authreq: "login", exact: true, path: "/company-value-list/company-value", component: Component.CompanyValueManagement
    }, {
        authreq: "login", exact: true, path: "/company-value-list/:id", component: Component.CompanyValueManagement
    }, {
        authreq: "login", exact: true, path: "/objective-type-list", component: Component.ObjectiveTypeList
    }, {
        authreq: "login", exact: true, path: "/objective-type-list/objective-type", component: Component.ObjectiveTypeManagement
    }, {
        authreq: "login", exact: true, path: "/objective-type-list/:id", component: Component.ObjectiveTypeManagement
    }, {
        authreq: "login", exact: true, path: "/employee-phase-management", component: Component.PlanEmployeePhaseManagement
    }, {
        authreq: "login", exact: true, path: "/manage-employee-performance", component: Component.PlanEmployeePerformanceManagement
    }, {
        authreq: "login", exact: true, path: "/plan-list", component: Component.PlanList
    }, {
        authreq: "login", exact: true, path: "/plan-personal", component: Component.PlanListPersonal
    }, {
        authreq: "login", exact: true, path: "/plan-functional-manager", component: Component.PlanListFunctionalManager
    }, {
        authreq: "login", exact: true, path: "/plan-objectives", component: Component.PlanObjectives
    }, {
        authreq: "login", exact: true, path: "/performance-rating-list", component: Component.PerformanceRatingList
    }, {
        authreq: "login", exact: true, path: "/performance-rating-list/performance-rating", component: Component.PerformanceRatingManagement
    }, {
        authreq: "login", exact: true, path: "/performance-rating-list/:id", component: Component.PerformanceRatingManagement
    }, {
        authreq: "login", exact: true, path: "/employee-classification-list", component: Component.EmployeeClassificationList
    }, {
        authreq: "login", exact: true, path: "/employee-classification-list/employee-classification", component: Component.EmployeeClassificationManagement
    }, {
        authreq: "login", exact: true, path: "/employee-classification-list/:id", component: Component.EmployeeClassificationManagement
    }, {
        authreq: "login", exact: true, path: "/meta-data-attribute-type-list", component: Component.SysMDAttributeTypeList
    }, {
        authreq: "login", exact: true, path: "/user-application-settings-list", component: Component.UserApplicationSettingsList
    }, {
        authreq: "login", exact: true, path: "/meta-data-attribute-type-list/meta-data-attribute-type-management", component: Component.SysMDAttributeTypeManagement
    }, {
        authreq: "login", exact: true, path: "/meta-data-db-object-list", component: Component.SysMDDBObjectList
    }, {
        authreq: "login", exact: true, path: "/meta-data-db-object-attribute-list", component: Component.SysMDDBObjectAttributeList
    }, {
        authreq: "login", exact: true, path: "/ip-control-management-list", component: Component.IPControlList
    }, {
        authreq: "login", exact: true, path: "/ip-control-management-list/ip-control-management", component: Component.IPControlManagement
    }, {
        authreq: "login", exact: true, path: "/ip-control-management-list/:id", component: Component.IPControlManagement
    }, {
        authreq: "login", exact: true, path: "/succession-term-list/:id", component: Component.SuccessionTermManagement
    }, {
        authreq: "login", exact: true, path: "/talent-plan-management", component: Component.TalentManagementPlanList
    }, {
        authreq: "login", exact: true, path: "/talent-plan-management/new", component: Component.TalentManagementPlan
    }, {
        authreq: "login", exact: true, path: "/talent-plan-management/:id", component: Component.TalentManagementPlan
    }, {
        authreq: "login", exact: true, path: "/talent-plan-employee-list", component: Component.TalentPlanEmployeeList
    }, {
        authreq: "login", exact: true, path: "/self-competency-assessment/:TMEmpPosAssessmentID", component: Component.CompetencyAssessment
    }, {
        authreq: "login", exact: true, path: "/legal-entity-management-list/legal-entity-management", component: Component.LegalEntitiesManagement
    }, {
        authreq: "login", exact: true, path: "/legal-entity-management-list/:id", component: Component.LegalEntitiesManagement
    }, {
        authreq: "login", exact: true, path: "/legal-entity-management-list", component: Component.LegalEntitiesManagementList
    }, {
        authreq: "login", exact: true, path: "/position-category-list/position-category-management", component: Component.PositionClassManagement
    }, {
        authreq: "login", exact: true, path: "/position-category-list/:id", component: Component.PositionClassManagement
    }, {
        authreq: "login", exact: true, path: "/position-category-list", component: Component.PositionClassManagementList
    }, {
        authreq: "login", exact: true, path: "/tag-management", component: Component.TagCategoryManagement
    }, {
        authreq: "login", exact: true, path: "/bonus-plan-list/bonus-plan-management", component: Component.BonusPlan
    }, {
        authreq: "login", exact: true, path: "/bonus-plan-list/:id", component: Component.BonusPlan
    }, {
        authreq: "login", exact: true, path: "/bonus-plan-list", component: Component.BonusPlanList
    }, {
        authreq: "login", exact: true, path: "/bonus-objectives", component: Component.BonusObjectives
    }, {
        authreq: "login", exact: true, path: "/my-team-bonus", component: Component.MyTeamBonus
    }, {
        authreq: "login", exact: true, path: "/bonus-rules-list", component: Component.BonusProcessRulesList
    }, {
        authreq: "login", exact: true, path: "/bonus-rules-list/bonus-rules-management", component: Component.BonusProcessRulesManagement
    }, {
        authreq: "login", exact: true, path: "/bonus-rules-list/:id", component: Component.BonusProcessRulesManagement
    }, {
        authreq: "login", exact: true, path: "/bonus-performance-multiplier-list", component: Component.BonusPerformanceMultiplierList
    }, {
        authreq: "login", exact: true, path: "/employment-history-reason-list/employement-history-reason-management", component: Component.EmploymentHistoryReasonManagement
    }, {
        authreq: "login", exact: true, path: "/employment-history-reason-list", component: Component.EmploymentHistoryReasonList
    }, {
        authreq: "login", exact: true, path: "/employment-history-reason-list/:id", component: Component.EmploymentHistoryReasonManagement
    }, {
        authreq: "login", exact: true, path: "/employee-bonus-overview-list", component: Component.EmployeeBonusOverviewList
    }, {
        authreq: "login", exact: true, path: "/employee-bonus-overview-list/:id", component: Component.EmployeeBonusOverviewManagement2
    }, {
        authreq: "login", exact: true, path: "/translation/:language", component: Component.LanguageTable
    }, {
        authreq: "login", exact: true, path: "/sso-list", component: Component.SSO
    }, {
        authreq: "login", exact: true, path: "/translation", component: Component.LanguageTableList
    }, {
        authreq: "login", exact: true, path: "/static-labels", component: Component.StaticLabels
    }, {
        authreq: "login", exact: true, path: "/home-template", component: Component.HomeCMS
    }, {
        authreq: "login", exact: true, path: "/terms-and-conditions-template", component: Component.TermsAndConditionTemplates
    }, {
        authreq: "login", exact: true, path: "/faq-settings", component: Component.CMS_FAQ
    }, {
        authreq: "login", exact: true, path: "/logo", component: Component.Upload
    }, {
        authreq: "login", exact: true, path: "/logout", component: Component.Logout
    }, {
        authreq: "login", exact: true, path: "/currency-management-list/currency-fx/:id", component: Component.CurrencyFXManagement
    },{
        authreq: "login", exact: true, path: "/bonus-approval-workflow", component: Component.BonusApprovalWorkflowView
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-list", component: Component.BonusWorkFlowList
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-list/bonus-workflow-management", component: Component.BonusWorkFlowManagement
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-list/:id", component: Component.BonusWorkFlowManagement
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-state-role-list", component: Component.BonusWorkFlowStateRoleList
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-state-role-list/bonus-workflow-state-role-management", component: Component.BonusWorkFlowStateRoleManagement
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-state-role-list/:id", component: Component.BonusWorkFlowStateRoleManagement
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-state-transition-list", component: Component.BonusWorkFlowStateTransitionList
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-state-transition-list/bonus-workflow-state-transition-management", component: Component.BonusWorkFlowStateTransitionManagement
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-state-transition-list/:id", component: Component.BonusWorkFlowStateTransitionManagement
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-state-list", component: Component.BonusWorkflowStateLists
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-state-list/bonus-workflow-state-list-management", component: Component.BonusWorkflowStateListManagement
    },{
        authreq: "login", exact: true, path: "/bonus-workflow-state-list/:id", component: Component.BonusWorkflowStateListManagement
    },{
        authreq: "login", exact: true, path: "/organisation-unit-type-list", component: Component.OrganisationUnitTypeList
    },{
        authreq: "login", exact: true, path: "/organisation-unit-type-list/organisation-unit-type-management", component: Component.OrganisationUnitTypeManagement
    },{
        authreq: "login", exact: true, path: "/organisation-unit-type-list/:id", component: Component.OrganisationUnitTypeManagement
    },{
        authreq: "login", exact: true, path: "/label-management", component: Component.StaticLanguageManagement
    },{
        authreq: "login", exact: true, path: "/default-label-management", component: Component.DefaultLabelManagement
    },{
        authreq: "login", exact: true, path: "/line-of-business-list", component: Component.LineBusinessList
    },{
        authreq: "login", exact: true, path: "/line-of-business-list/line-of-business-management", component: Component.LineBusinessListManagement
    },{
        authreq: "login", exact: true, path: "/line-of-business-list/:id", component: Component.LineBusinessListManagement
    },{
        authreq: "login", exact: true, path: "/performance-type-list", component: Component.PerformanceTypeList
    },{
        authreq: "login", exact: true, path: "/performance-type-list/performance-type-management", component: Component.PerformanceTypeManagement
    },{
        authreq: "login", exact: true, path: "/performance-type-list/:id", component: Component.PerformanceTypeManagement
    },{
        authreq: "login", exact: true, path: "/performance-type-explanation-list", component: Component.PerformanceTypeExplanationList
    },{
        authreq: "login", exact: true, path: "/performance-type-explanation-list/performance-type-explanation-management", component: Component.PerformanceTypeExplanationManagement
    },{
        authreq: "login", exact: true, path: "/performance-type-explanation-list/:id", component: Component.PerformanceTypeExplanationManagement
    },{
        authreq: "free", exact: true, path: "/faq", component: Component.FAQ
    },{
        authreq: "free", exact: true, path: "/kpi-list", component: Component.KPIList
    },{
        authreq: "free", exact: true, path: "/kpi-list/kpi-management", component: Component.KPIManagement
    },{
        authreq: "free", exact: true, path: "/kpi-list/:id", component: Component.KPIManagement
    }
    ],
}]