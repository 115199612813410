import React from 'react';
import { Link } from 'react-router-dom';



export default class Page404 extends React.Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template error_template">
                            <h1>Oops!</h1>
                            <h2>404 Not Found</h2>
                            <div className="error-details">
                                Sorry, an error has occured, Requested page not found!
                            </div>
                            <div className="error-actions error_actions">
                                <Link to="/" className="btn btn-primary btn-md error_actions_btn"> <span className="glyphicon glyphicon-home"></span>
                                     Take Me Home</Link>
                                <Link to="/" className="btn btn-default btn-md" ><span className="glyphicon glyphicon-envelope"></span>
                                     Contact Support</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}