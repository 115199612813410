import React from 'react';
import ReactLoading from 'react-loading';

import Sidebar from '../layouts/bulma-private/sidebar';
import NavBar from '../layouts/bulma-private/navbar';
import ChildNavbar from '../layouts/bulma-private/child-navbar';

import 'bulma/css/bulma.css';
import '../../assets/static/bulkit/assets/css/icons.min.css';
import '../../assets/static/bulkit/scss/dashboard.scss';


function Loading () {   
  
    return (
        <div>
          <Sidebar/>
          <NavBar/>
          <ChildNavbar/>
            <div id="dashboard-wrapper">
                <div className="main-wrapper">
                    <ReactLoading type={'bubbles'} color={'#FF0000'} />
                </div>
            </div>
        </div>
    )

};



export default Loading;