const initialState = {
    labelToggle: false
}

const toggleLabelReducer = (state = initialState, action) => {
    switch(action.type){
        case 'TOGGLE_LABEL': 
            return {
                ...state,
                labelToggle: !state.labelToggle
            }
        
        default:
            return state;
    }
}


export default toggleLabelReducer;