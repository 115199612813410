import React from "react";
import { Redirect } from "react-router-dom";

import auth from "./auth";

export function loginRequired(component) {
    if (auth.isAuthenticated()) {
        return component;
    } else {
        return (() => (
            <Redirect
                to={{
                    pathname: "/login"
                }}
            />
        ));
    }
}

export function logoutRequired(component) {
    if (!auth.isAuthenticated()) {
        return component;
    } else {
        return (() => (
            <Redirect
                to={{
                    pathname: "/logout"
                }}
            />
        ));
    }
}

export function termsRequired(component) {
    if (auth.isWebsiteTermSigned()) {
        return component;
    } else {
        if(window.location.pathname != '/terms-and-condition'){
            window.location.replace('/terms-and-condition');
        }
       
    }
}


export default { loginRequired, logoutRequired, termsRequired };