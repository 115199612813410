import React from 'react';

export default class ChildNavbar extends React.Component {

    render() {
        return (
            <div className="mobile-nav is-hidden-desktop">
                <div className="container is-fluid">
                    <div className="mobile-nav-inner">
                        {/* <a className="mobile-item">
                            <i className="sl sl-icon-bell"></i>
                            <span className="new-circle gelatine"></span>
                        </a>
                        <a className="mobile-item">
                            <i className="sl sl-icon-envelope-open"></i>
                            <span className="new-circle gelatine"></span>
                        </a>
                        <a className="mobile-item modal-trigger" data-modal="search-modal">
                            <i className="sl sl-icon-magnifier"></i>
                        </a> */}
                        <a className="mobile-item quickview-trigger child-navbar" data-show="quickview" data-target="main-quickview">
                            <i className="sl sl-icon-bubble"></i>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}