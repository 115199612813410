import React from 'react';
import menuJson from '../../../data/menu-v2.json';
import { Link } from 'react-router-dom';
import Auth from '../../../functions/auth';


export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuLocalStorage: null,
            employeePhoto: null
        };
    }

    render() {
        const state = this.state,
            menuLocalStorage = state.menuLocalStorage,
            employeePhoto = state.employeePhoto,
            menuProps = {
                menuLocalStorage,
                menuJson,
                employeePhoto
            };
        let mainMenuRender,
            childMenuRender;

        if (menuLocalStorage && menuJson) {
            mainMenuRender = (<MainMenu {...menuProps} />);
            childMenuRender = (<ChildMenu {...menuProps} />);
        }

        return (
            <div>
                {mainMenuRender}
                {childMenuRender}
            </div>

        )
    }

    UNSAFE_componentWillMount() {
        let menuLocalStorage = localStorage.getItem('EmployeeMenuAccess'),
            employeePhoto = localStorage.getItem('photo');

        if (menuLocalStorage) {
            menuLocalStorage = JSON.parse(menuLocalStorage);
        }

        if (employeePhoto === null || employeePhoto === "null") {
            employeePhoto = "../src/assets/images/default.png";
        } else {
            employeePhoto = `${API_URL_ROOT_FS}${employeePhoto}`;
        }

        this.setState({
            menuLocalStorage,
            employeePhoto
        });
    }
};

class MainMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            employeePhoto: localStorage.getItem('photo'),
            employeeID: localStorage.getItem('employeeId')
        };
    }

    render() {
        const _this = this,
            props = _this.props,
            menuLocalStorage = props.menuLocalStorage,
            menuJson = props.menuJson,
            employeePhoto = props.employeePhoto,
            employeeID = _this.state.employeeID;
        let itemsRender = [],
            profileRender;

        if (menuJson) {
            itemsRender = menuJson.map((_menuJson, index) => {
                const item = menuLocalStorage.find(_menuLocalStorage => {
                    return _menuLocalStorage.ApplicationFunctionID == _menuJson.applicationFunctionID;
                });

                if (item) {
                    let itemRender;
                    if (_menuJson.link) {
                        itemRender = <Link to={_menuJson.link}>
                            <i className={`sl ${_menuJson.icon}`}></i>
                        </Link>
                    } else if(_menuJson.isExternalLink) {
                        itemRender = <a href={EXTERNAL_LINKS[_menuJson.link].SideBarURL} target="_blank">
                            <i className={`sl ${_menuJson.icon}`}></i>
                        </a>
                    } else {
                        itemRender = <a href={_menuJson.link}>
                            <i className={`sl ${_menuJson.icon}`}></i>
                        </a>
                    }

                    return (
                        <li key={index} id={`menu-${item.ApplicationFunctionID}`} className="side-icon" title={item.Name}
                            data-toggle="tooltip"
                            data-child-menu={_menuJson.elementID}>
                            {itemRender}
                        </li>
                    );
                }
            });
        }

        if (employeeID === "0") {
            profileRender = (
                <a className="profile-trigger" href="#" onClick={e => e.preventDefault()}>
                    <img className="main-menu-avatar" src={employeePhoto} alt="" />
                </a>
            );
        } else {
            profileRender = (
                <a className="profile-trigger" href={`employee-list/${employeeID}`}>
                    <img className="main-menu-avatar" src={employeePhoto} alt="" />
                </a>
            );
        }

        return (
            <nav className="main-menu">
                <div className="main-menu-inner">
                    <ul>
                        {itemsRender}
                    </ul>
                    <ul className="profile">
                        <li>{profileRender}</li>
                    </ul>
                </div>
            </nav>
        );
    }
}

class ChildMenu extends React.Component {
    getChildItems(params) {
        const _this = this,
            _menuJson = params._menuJson,
            menuLocalStorage = params.menuLocalStorage,
            children = _menuJson.children;

        let childrenRender = children.map((child, index) => {
            const item = menuLocalStorage.find(_menuLocalStorage => {
                return _menuLocalStorage.ApplicationFunctionID == child.applicationFunctionID;
            }), appendToLink = child.appendToLink;
            let shouldProceed = true;

            if (appendToLink == "Employee ID" && Auth.getEmployeeId() == 0) {
                shouldProceed = false;
            }

            if (item && shouldProceed) {
                if (child.children.length > 0) {
                    let subChildrenRender = child.children.map((_child, _index) => {
                        const _item = menuLocalStorage.find(_menuLocalStorage => {
                            return _menuLocalStorage.ApplicationFunctionID == _child.applicationFunctionID;
                        });

                        if (_item) {
                            return _this.subChildTemplate({
                                child: _child,
                                item: _item,
                                key: _index
                            });
                        }
                    });

                    return (
                        <li className="have-children" key={index}>
                            <a className="parent-link"><span className="material-icons">{child.icon}</span>{item.Name}</a>
                            <ul>
                                {subChildrenRender}
                            </ul>
                        </li>
                    )
                } else {
                    return _this.mainChildTemplate({
                        child, item,
                        key: index
                    });
                }
            }
        });

        return childrenRender;
    }

    subChildTemplate(params) {
        const child = params.child,
            item = params.item,
            key = params.key;
        let itemRender,
            name = item.Name;

        if (child.link) {
            if (name) {
                name = name.trim();
            }

            itemRender = <Link to={appendLink({ item: child })}>{item.Name}</Link>
        } else {
            itemRender = <a href={child.link}>{item.Name}</a>
        }

        return (
            <li key={key}>{itemRender}</li>
        );
    }

    mainChildTemplate(params) {
        const child = params.child,
            item = params.item,
            key = params.key;
        let itemRender,
            name = item.Name;

        if (child.link) {
            if (name) {
                name = name.trim();
            }

            itemRender = <Link to={appendLink({ item: child })}><span className="material-icons">{child.icon}</span>{item.Name}</Link>
        } else {
            itemRender = <a href={child.link}><span className="material-icons">{child.icon}</span>{item.Name}</a>
        }

        return (
            <li key={key}>{itemRender}</li>
        )
    }

    mainTemplate(params) {
        const menuJson = params.menuJson,
            children = params.children,
            key = params.key;

        return (
            <ul key={key} id={menuJson.elementID} className="sidebar-menu animated preFadeInRIght fadeInRight">
                {children}
            </ul>
        )
    }

    render() {
        const _this = this,
            props = _this.props,
            menuLocalStorage = props.menuLocalStorage,
            menuJson = props.menuJson;
        let itemsRender = null;

        if (menuJson) {
            itemsRender = menuJson.map((_menuJson, index) => {
                if (_menuJson.children.length > 0) {
                    const getChildItemsParams = {
                        _menuJson, menuLocalStorage
                    };

                    let childSidebarMenu = _this.getChildItems(getChildItemsParams);

                    return _this.mainTemplate({
                        menuJson: _menuJson,
                        children: childSidebarMenu,
                        key: index
                    });
                } else {

                }
            });
        }

        return (
            <nav className="child-menu">
                <div className="child-menu-inner">
                    <ul className="child-header-container">
                        <li className="child-header">
                            <div className="sidebar-title">Navigation</div>
                            <a id="close-child-menu" href="#" onClick={e => e.preventDefault()} className="menu-wrapper">
                                <span className="icon-box-toggle ef-4">
                                    <span className="rotate">
                                        <i className="icon-line-top">  	 </i>
                                        <i className="icon-line-center">  </i>
                                        <i className="icon-line-bottom">  </i>
                                    </span>
                                </span>
                            </a>
                        </li>
                    </ul>
                    {itemsRender}
                </div>
            </nav>
        );
    }
}

const appendLink = (params) => {
    const item = params.item,
        appendToLink = item.appendToLink;
    let link = item.link;

    if (appendToLink) {
        switch (appendToLink) {
            case "Employee ID":
                link = `${link}/${Auth.getEmployeeId()}`;
                break;
        }
    }

    return link;
}