import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Auth from '../../../functions/auth';
import GeneralFunctions from '../../../functions/general_function1';

export default class NavBar extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			secondary: '#F02137',
			languageList: {},
			languages: [],
			activeLanguages: []
		};

		this.selectedLanguage = Auth.getCurrentLanguage();
		this.onClickChangeLanguage = this.onClickChangeLanguage.bind(this);
	}

	componentDidMount () {
		this.getLanglistJSON();
	}

	getLanglistJSON () {
		const _this = this;
		let languageList = localStorage.getItem('languageList'),
			  languages = localStorage.getItem('languages'),
			  activeLanguages = localStorage.getItem('activeLanguages');

		if (languageList == null) {
			axios
				.get('../src/data/listLanguage.json', {})
				.then((res) => {
					if (res.status === 200) {
						languageList = res.data.Languages;

						if (languageList) {
							localStorage.setItem('languageList', JSON.stringify(languageList));
						}

						_this.setState({
							languageList
						});
					}
				})
				.catch(function (thrown) {
					console.log(thrown);
				});
		} else {
			languageList = JSON.parse(languageList);

			_this.setState({
				languageList
			});
		}

		if (activeLanguages == null) {
			axios
				.get(API_URL_CMS_LANGUAGE + '/active_languages/', {})
				.then((res) => {
					if (res.status === 200) {
			
							activeLanguages = res.data;

							if (activeLanguages) {
								localStorage.setItem('activeLanguages', JSON.stringify(activeLanguages));
							}

							_this.setState({
								activeLanguages
							});
							
					}
				})
				.catch(function (thrown) {
					console.log(thrown);
				});
		} else {
			activeLanguages = JSON.parse(activeLanguages);

			_this.setState({
				activeLanguages
			});
		}

		if (languages == null) {
			axios
				.get(API_URL_CMS + '/languages/', {})
				.then((res) => {
					if (res.status === 200) {
						
							languages = res.data.languageList;

							if (languages) {
								localStorage.setItem('languages', JSON.stringify(languages));
							}

							_this.setState({
								languages
							});
							
					}
				})
				.catch(function (thrown) {
					console.log(thrown);
				});
		} else {
			languages = JSON.parse(languages);

			_this.setState({
				languages
			});
		}

		this.selectedLanguage = Auth.getCurrentLanguage();
	}

	onClickChangeLanguage (params) {
		let activeLanguage = params.activeLanguage,
			employeeID = Auth.getEmployeeId(),
			formData = new FormData();
		if (activeLanguage) {
			formData.append('defaultLanguage', JSON.stringify(activeLanguage).replace(/[\""]/g, '\\"'));

			formData.append('EmployeeID', employeeID);

			waitingDialog.show('Changing default language...', {
				dialogSize: 'sm',
				progressType: 'danger'
			});

			axios({
				method: 'PATCH',
				baseURL: API_URL_CMS,
				url: '/default_labels/',
				headers: {
					Token: Auth.getToken()
				},
				data: formData
			})
				.then((response) => {
					let data = response.data,
						DefaultLanguage;

					if (data) {
						DefaultLanguage = data.DefaultLanguage;
					}

					waitingDialog.hide();
					if (DefaultLanguage) {
						let defaultLanguageJSON = JSON.parse(DefaultLanguage);
						let sysApplicationFunctionID = data.SystemApplicationFunctionIDs.Sys_ApplicationFunction;

						Auth.setLanguage(defaultLanguageJSON.TwoLetterCode.toUpperCase());
						Auth.setCurrentLanguage(DefaultLanguage);
						Auth.setLanguageID(defaultLanguageJSON.ID);
						Auth.removeEmployeeMenuAccess();
						Auth.setEmployeeMenuAccess(sysApplicationFunctionID); // Sets the menus in the system
						GeneralFunctions.updateStaticLabel(defaultLanguageJSON.ID);
					}
					waitingDialog.hide();
				})
				.catch((reponse) => {
					console.log(reponse);
					waitingDialog.hide();
				});
		}
	}

	render () {
		const _this = this,
			state = _this.state;

		let selectedLanguage = _this.selectedLanguage,
			renderLanguage;

		if (selectedLanguage) {
			let languageList = state.languageList,
				activeLanguages = state.activeLanguages,
				languages = state.languages,
				activeLanguagesInUse = [],
				renderActiveLanguage = [];

			activeLanguages.forEach((_activeLanguages) => {
				if (_activeLanguages.InUse) {
					let twoLetterCode = _activeLanguages.TwoLetterCode;

					if (twoLetterCode) {
						if (twoLetterCode != selectedLanguage.TwoLetterCode) {
							renderActiveLanguage.push(
								<li
									className={`language-options`}
									onClick={() => {
										_this.onClickChangeLanguage({
											activeLanguage: _activeLanguages
										});
									}}
									key={twoLetterCode}
								>
									<div className="language-content">
										<div className="language-text">
											<div className="text">
												<span
													className={`language-content flags selection flag-${_activeLanguages.FlagCode}`}
												/>
												<span>{_activeLanguages.Value}</span>
											</div>
										</div>
									</div>
								</li>
							);
						}
					}
				}
			});

			renderLanguage = (
				<div className="navbar-item drop-pop is-centered nav-icon">
					<a className="nav-inner">
						<span className={`flags flag-${selectedLanguage.FlagCode}`} title={selectedLanguage.Value} />
					</a>
					<div className="drop-wrapper languages-drop">
						<div className="drop-inner has-arrow">
							<div className="languages-header has-text-centered">
								<h3>Languages</h3>
							</div>
							<div className="languages-body">
								<ul className="languages-list nav" >
									{renderActiveLanguage}
									<li>
										<div className="language-content ">
											<div className="language-text">
												<div className="text" />
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			);
		}

		let switchTranslateColorRed = (
			<span className="text-danger">
				"OFF"<br />On Page Translate
			</span>
		);
		let switchTranslateColorGreen = (
			<span className="text-success">
				"ON"<br />On Page Translate
			</span>
		);

		let renderTranslateSwitch = (
			<div className="navbar-item">
				<label className="switch">
					<input
						type="checkbox"
						onChange={this.props.getTranslatableChangeState}
						checked={this.props.labelToggle}
						id="togBtn"
					/>
					<div className="slider1 round" title="Translate on Page">
						<span className="on">ON</span>
						<span className="off">OFF</span>
					</div>
				</label>
			</div>
		);

		return !Auth.isUserAuthenticated() ? (
			<div />
		) : (
			<nav className="navbar dashboard-nav has-shadow">
				<div className="container is-fluid">
					<div className="navbar-brand">
						<div className="navbar-item reader-switch">
							<div className="field">
								<input
									id="reader-mode-switch"
									type="checkbox"
									name="reader-mode-switch"
									className="switch is-outlined is-primary is-small styled-checkbox"
								/>
								<label id="reader-mode-toggle" htmlFor="reader-mode-switch" className="" />
							</div>
						</div>
						<div className="navbar-item logo">
							<Link to="/">
								<img src={`${API_URL_ROOT_FS}/media/content/logo/logo_header.PNG`} alt="Logo" />
							</Link>
						</div>
					</div>
					<div className="navbar-menu">
						<div className="navbar-end">
							{Auth.getIsSysAdmin() == 'true' ? renderTranslateSwitch : ''}
							{renderLanguage}
						</div>
					</div>
				</div>
			</nav>
		);
	}
}